
import React, {Fragment, Component} from "react";
import { HashLink as Link } from 'react-router-hash-link';
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class HeaderHome extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isExpanded: false
            
        };
        this.state = {
            isExpandedMenu: false
            
        };
    }
    handleToggle(e) {
        e.preventDefault();
        this.setState({
            isExpanded: !this.state.isExpanded
        });
    }

    handleToggleMenu(e) {
        e.preventDefault();
        this.setState({
            isExpandedMenu: !this.state.isExpandedMenu
        });
    }

    render() {
        const { isExpanded } = this.state;
        const { isExpandedMenu } = this.state;
        return (
                <Fragment>
                    <div className="mean-bar">
                        <a href="#nav" className="meanmenu-reveal" className={`meanmenu-reveal ${isExpanded ? "meanclose" : ""}`} onClick={e => this.handleToggle(e)} >
                            X
                            <span></span>
                            <span></span>
                            <span></span>
                        </a>
                        <nav className="mean-nav">
                            <ul className={` ${isExpanded ? "d-block" : "d-none"}`}>
                        <li>
                            <Link className="active" to="home">home</Link>
                        </li>
                        <li>
                            <Link to="services">Services</Link>
                            <ul className={` ${isExpandedMenu ? "d-block" : "d-none"}`} >
                            <li>
                                <Link to="services#carpet-steam-cleaning">Carpet Steam Cleaning</Link>
                            </li>
                            <li>
                                <Link to="services#tile-grout-clean">Tile & Grout Clean</Link>
                            </li>
                            <li>
                                <Link to="services#upholstery-steam-clean">Upholstery Steam Clean</Link>
                            </li>
                            <li>
                                <Link to="services#mattress-cleaning">Mattress cleaning</Link>
                            </li>
                            <li>
                                <Link to="services#window-cleaning-service">Window cleaning Service</Link>
                            </li>
                            <li>
                                <Link to="services#rug-cleaning">Rug Cleaning</Link>
                            </li>
                            <li>
                                <Link to="services#flood-damage-cleaning">Flood Damage Cleaning</Link>
                            </li>
                            </ul>
                            <a className="mean-expand" href="#" onClick={e => this.handleToggleMenu(e)}>
                                <span className={` ${isExpandedMenu ? "d-none" : "d-block"}`}>+</span>
                                <span className={` ${isExpandedMenu ? "d-block" : "d-none"}`}>-</span>
                            </a></li>
                        <li>
                            <Link to="about">About Us</Link>
                        </li>
                        <li><Link to="gallery">Gallery</Link></li>	
                        <li className="mean-last">
                        <Link to="contact">Contact Us</Link>
                        </li>
                        
                        </ul>
                        </nav>
                    </div>

                    <div className="header-top">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-9">
                                    <div className="header-left">
                                        <ul>
                                            <li>Melbourne</li>
                                            <li>
                                                <a href={"mailto:info@successcps.com.au"}>info@successcps.com.au</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="header-right">
                                        <ul>
                                            <li>
                                                <a href={"#"}>0415 129 978</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>









                    <div className="hd-sec">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="logo">
                                        <Link to="home"><img src={require("../img/logo.png")} alt="Success-cps Professional Cleaning"/>
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <div className="menu">
                                        
                                        <nav id="main-menu" className="main-menu">
                                            <ul>
                                                <li>
                                                    <Link className="active" to="home">home</Link>
                                                </li>
                                                <li>
                                                    <Link to="services">Services</Link>
                                                    <ul>
                                                        <li>
                                                            <Link to="services#carpet-steam-cleaning">Carpet Steam Cleaning</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="services#tile-grout-clean">Tile & Grout Clean</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="services#upholstery-steam-clean">Upholstery Steam Clean</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="services#mattress-cleaning">Mattress cleaning</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="services#window-cleaning-service">Window cleaning Service</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="services#rug-cleaning">Rug Cleaning</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="services#flood-damage-cleaning">Flood Damage Cleaning</Link>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <Link to="about">About Us</Link>
                                                </li>
                                                <li><Link to="gallery">Gallery</Link></li>
                                                <li>
                                                    <Link to="contact">Contact Us</Link>
                                                </li>

                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
        );
    }
}

export default HeaderHome;
