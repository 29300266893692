import React, { Fragment } from "react";
import { Slide } from 'react-slideshow-image';
import image1 from '../../img/slider/1.jpg';
import image2 from '../../img/slider/2.jpg';
import image3 from '../../img/slider/3.jpg';
import image4 from '../../img/slider/4.jpg';

const slideImages = [
    image1,
    image2,
    image3,
    image4
];

const Slideshow = () => {

    return (
        <Fragment>
            <div className="slide-container">
                <Slide>
                        <div className="each-slide">
                            <div style={{'backgroundImage': `url(${slideImages[0]})`}}>
                                <div>
                                   <h1> WELCOME TO SUCCESS-CPS <span>CARPET STEAM CLEANING</span></h1>
                                </div>
                            </div>
                        </div>
                        <div className="each-slide">
                            <div style={{'backgroundImage': `url(${slideImages[1]})`}}>
                                <div>
                                    <h1>HIGH QUALITY CARPET <span>CLEANING SERVICE IN MELBOURNE </span></h1>
                                </div>
                            </div>
                        </div>
                        <div className="each-slide">
                            <div style={{'backgroundImage': `url(${slideImages[2]})`}}>
                                <div>
                                    <h1>WELCOME TO SUCCESS-CPS <span>CARPET STEAM CLEANING</span></h1>
                                </div>
                            </div>
                        </div>
                        <div className="each-slide">
                            <div style={{'backgroundImage': `url(${slideImages[3]})`}}>
                                <div>
                                    <h1>HIGH QUALITY CARPET <span>CLEANING SERVICE IN MELBOURNE </span></h1>
                                </div>
                            </div>
                        </div>
                </Slide>
            </div>
        </Fragment>
    );

}

export default Slideshow;
