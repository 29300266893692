import React, { Fragment } from "react";
import { HashLink as Link } from 'react-router-hash-link';
import ServicesBanner from '../../img/services-banner.jpg';
import FooterSection from "../footer/FooterSection";

let sectionStyle = {
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundImage: "url(" + ServicesBanner + ")"
};

const ServicesPage = () => {
    return (
        <Fragment>
            <div className="pagehding-sec"
                 style={sectionStyle}>
                <div className="images-overlay"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-heading">
                                <h1>Services</h1>
                            </div>
                            <div className="breadcrumb-list">
                                <ul>
                                    <li><Link to="home">Home</Link></li>
                                    <li><Link to="#">Services</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <section className=" pt-100">
                <div className="container">
                    <div className="row" id="carpet-steam-cleaning">
                        <div className="col-md-6">
                            <img src={require("../../img/services/1.jpg")} width="100%"/>
                        </div>
                        <div className="col-md-6">
                            <h2>Carpet Steam Cleaning</h2>
                            <p>Success-cps Carpet Cleaning recommends the steam cleaning process, also known as hot
                                water
                                extraction, as the preferred method for cleaning your carpets. We pride ourselves in
                                working
                                to the highest Industry Standards.</p>
                            <p>Our powerful equipment and safe chemicals will give you the deepest clean. We use
                                environmentally safe and green chemicals wherever possible.</p>
                            <p>Our carpets will feel softer and smell fresher and you will appreciate a healthier home
                                for
                                you and your family. And remember, you can use your carpets straight away.</p>
                        </div>
                    </div>
                    <hr/>
                    <div className="row" id="tile-grout-clean">
                        <div className="col-md-6">
                            <h2>Tile & Grout Cleaning</h2>
                            <p>Success-cps Professional steam cleaning service technicians use state of the art steam
                                extraction machinery to steam clean your tiles and grout. With tile and grout cleaning
                                in Melbourne, our technicians are carefully trained to identify the type of your tiles,
                                different tiles require different cleaning techniques and it is very important that you
                                have a well-trained technician to complete your job.</p>
                            <p>Success-cps Professional steam cleaning service tile and grout cleaning method is to
                                apply specialized cleaning product to your tiles, then using our powerful steam
                                extraction machine we use high temperature combined with high pressure to steam clean
                                your tiles.</p>
                            <p>Success-cps Professional steam cleaning service leaves your floors walk on dry, and all
                                the dirt and water from the cleaning process is sucked into the holding tank in our
                                powerful steam extraction machine. So there is no mess, all you need to do is sit back
                                and enjoy your new looking tiles again. With our state of the art methods, your tiles
                                and grout are left hygienic clean. There is no mess or splash up your walls. Your tiles
                                and grout are dry when we leave.</p>
                        </div>
                        <div className="col-md-6">
                            <img src={require("../../img/services/2.jpg")} width="100%"/>
                        </div>
                    </div>
                    <hr/>
                    <div className="row" id="upholstery-steam-clean">
                        <div className="col-md-6">
                            <img src={require("../../img/services/3.jpg")} width="100%"/>
                        </div>
                        <div className="col-md-6">
                            <h2>Upholstery Steam Cleaning</h2>
                            <p>Success-cps Professional steam cleaning service Upholstery steam Cleaning are more
                                than just carpet cleaning. You can trust us to skilfully clean and restore your
                                lounge suite and dining room chairs with our safe and thorough upholstery cleaning
                                methods.</p>
                            <p>We pride ourselves in providing quality upholstery cleaning services and working to
                                the highest Industry Standards. Our powerful equipment and safe chemicals will give
                                you the deepest clean. We use environmentally safe and green chemicals wherever
                                possible. You have the choice of either steam clean or dry clean. With Upholstery
                                cleaning your lounge suite will feel softer and smell fresher and you will
                                appreciate a healthier result for you and your family</p>
                        </div>
                    </div>
                    <hr/>
                    <div className="row" id="mattress-cleaning">
                        <div className="col-md-6">
                            <h2>Mattress cleaning</h2>
                            <p>We carefully remove all dust mites, their excrement and all detritus from your
                                mattress using our specialist equipment. We then thoroughly dry clean your
                                mattress removing any further dirt. We then treat your mattress with a
                                specialist solution.</p>
                            <p>Your mattress is now clean and protected giving you complete peace of mind. You
                                can use your mattress almost immediately as it is completely dry within 15
                                minutes.</p>
                            <p>Please note: Some mattresses may require steam cleaning and this will extend the
                                dry time. This service is also ideal for rest homes, motels, hotels, boarding
                                houses, pre-schools, dormitories, cruise ships in fact most anywhere.</p>
                        </div>
                        <div className="col-md-6">
                            <img src={require("../../img/services/4.jpg")} width="100%"/>
                        </div>
                    </div>
                    <hr/>
                    <div className="row" id="window-cleaning-service">
                        <div className="col-md-6">
                            <img src={require("../../img/services/7.jpg")} width="100%"/>
                        </div>
                        <div className="col-md-6">
                            <h2>Window cleaning Service</h2>
                            <p>Washing windows is one of those household chores that no one really looks
                                forward to and it is not a task which many people easily make time for. Yet
                                windows that are cleaned professionally look fantastic and puts the
                                finishing touch on your home.</p>
                            <p>Clean windows can really give the appearance of your home, both inside and
                                out, a boost, and let in more light cutting down on energy costs during
                                winter. Windows provide your own little picture boxes of the world. You sit
                                at them to read by natural light, you watch your kids play outside and you
                                place your plants next to them to grow.</p>
                        </div>
                    </div>
                    <hr/>
                    <div className="row" id="rug-cleaning">
                        <div className="col-md-6">
                            <h2>Rug Cleaning</h2>
                            <p>Success-cps professional carpet cleaning offer the best professional rug
                                cleaning service that will restore your rugs to their original lively
                                colours. Our rug-cleaning technicians are trained specially for rugs to
                                guarantee the proper handling and cleaning of your most beloved
                                rugs.</p>
                            <p>Rugs are always prone to dirt because they are often used in areas like
                                the living room or the kitchen where there is frequent foot traffic.
                                Rugs in these areas trap dirt, odours, and other particles from food
                                items and other stuff. These substances can be embedded deep in your rug
                                and they can be very difficult to remove. This is the main reason why
                                professional rug cleaners are needed in these types of situations.</p>
                        </div>
                        <div className="col-md-6">
                            <img src={require("../../img/services/6.jpg")} width="100%"/>
                        </div>
                    </div>
                    <hr/>
                    <div className="row" id="flood-damage-cleaning">
                        <div className="col-md-6">
                            <img src={require("../../img/services/5.jpg")} width="100%"/>
                        </div>
                        <div className="col-md-6">
                            <h2>Flood Damage Cleaning</h2>
                            <p>On arrival we first inspect the affected areas, and then with the
                                help of moisture meter we mark moisture over the affected areas. If
                                your carpets or furnishing are being affected by flood water then we
                                store and preserve in them in a dry kind of environment. For carpet
                                and furnishing cleaning we use sanitizer and steam clean it.</p>
                            <p>We believe to get your life back to normal as soon as possible. After
                                your valuables are being steam cleaned and dry we relocate them to
                                their places. Our expert would help you in taking extra care for the
                                affected furniture. Next time if your property is affected by flood
                                don’t forget to call us.</p>
                            <p>You cannot stop natural calamities and loss cause by natural
                                calamities is always huge and expensive. If flood hits your
                                workplace or home then it’s kind of traumatic event that need to be
                                taken care of. After the flood is over much bacteria and germs are
                                left over in the dust. We drain out the affected areas and sanitize
                                to make it healthy environment. Our professional cleaners are expert
                                and they adapt the best possible methodology for cleaning. We also
                                deodorize the atmosphere so that the stinking smell is not present
                                there anymore.</p>

                        </div>
                    </div>
                </div>
            </section>
        <FooterSection/>
        </Fragment>
    );
}

export default ServicesPage;
