import React, { Fragment } from "react";
import FooterSection from "../footer/FooterSection";
import ServicesBanner from "../../img/gallery.jpg";
import { HashLink as Link } from 'react-router-hash-link';

let sectionStyle = {
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundImage: "url(" + ServicesBanner + ")"
};

const GalleryPage = () => {
    return(
        <Fragment>
            <div className="pagehding-sec"
                 style={sectionStyle}>
                <div className="images-overlay"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-heading">
                                <h1>Gallery</h1>
                            </div>
                            <div className="breadcrumb-list">
                                <ul>
                                    <li><Link to="home">Home</Link></li>
                                    <li><Link to="#">gallery</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <section className=" pt-100">
                <div className="container">
                    <div className="service-item  pb-100">
                        <div className="row">
                            <div className="col-md-4 col-sm-6">
                                <div className="inner">
                                    <div className="media">
                                        <div className="service-thumb">
                                            <iframe width="560" height="315"
                                                    src="https://www.youtube.com/embed/TSlAHAGZCqU" frameBorder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    allowFullScreen></iframe>
                                        </div>
                                        <div className="service-content">
                                            <h2><Link to="services#carpet-steam-cleaning">Carpet Steam Clean</Link>
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <div className="inner">
                                    <div className="media">
                                        <div className="service-thumb">
                                            <iframe width="560" height="315"
                                                    src="https://www.youtube.com/embed/9EZ3XQFUtW4" frameBorder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    allowFullScreen></iframe>
                                        </div>
                                        <div className="service-content">
                                            <h2><Link to="services#tile-grout-clean">Tile & Grout Clean</Link>
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <div className="inner">
                                    <div className="media">
                                        <div className="service-thumb">
                                            <iframe width="560" height="315"
                                                    src="https://www.youtube.com/embed/MqjgT-kttMs" frameBorder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    allowFullScreen></iframe>
                                        </div>
                                        <div className="service-content">
                                            <h2>
                                                <Link to="our-services.html#tile-grout-clean">Tile and Grout cleaning</Link>
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-6">
                                <div className="inner">
                                    <div className="media">
                                        <div className="service-thumb">
                                            <iframe width="560" height="315"
                                                    src="https://www.youtube.com/embed/qqwwmeEB9g8" frameBorder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    allowFullScreen></iframe>
                                        </div>
                                        <div className="service-content">
                                            <h2>
                                                <Link to="services#rug-cleaning">Professional Rug Cleaning</Link>
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-6">
                                <div className="inner">
                                    <div className="media">
                                        <div className="service-thumb">
                                            <iframe width="560" height="315"
                                                    src="https://www.youtube.com/embed/Y-iCxbEpV5M" frameBorder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    allowFullScreen></iframe>
                                        </div>
                                        <div className="service-content">
                                            <h2>
                                                <Link to="services#flood-damage-cleaning">Flood Restoration</Link>
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-6">
                                <div className="inner">
                                    <div className="media">
                                        <div className="service-thumb">
                                            <iframe width="560" height="315"
                                                    src="https://www.youtube.com/embed/m7NU1Yfszxc" frameBorder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    allowFullScreen></iframe>
                                        </div>
                                        <div className="service-content">
                                            <h2>
                                                <Link to="services#carpet-steam-cleaning">Car Interior Steam Cleaning</Link>
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <FooterSection/>
        </Fragment>
    );
}
export default GalleryPage;
