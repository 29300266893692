import React, { Fragment } from "react";
import { HashLink as Link } from 'react-router-hash-link';

const CertificationSection = () => {
    return(
        <Fragment>
            <div className="service pt-100 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-md-1 col-xs-0"></div>
                        <div className="col-md-2 col-xs-4"><img src={require("../../img/logos/eco.png")} width="100%"/></div>
                        <div className="col-md-2 col-xs-4"><img src={require("../../img/logos/child-friendly.png")} width="100%"/></div>
                        <div className="col-md-2 col-xs-4"><img src={require("../../img/logos/open7days.png")} width="100%"/></div>
                        <div className="col-md-2 col-xs-4"><img src={require("../../img/logos/pet.png")} width="100%"/></div>
                        <div className="col-md-2 col-xs-4"><img src={require("../../img/logos/satisfaction.png")} width="100%"/></div>
                    </div>
                </div>
            </div>
        </Fragment>
    );

}

export default CertificationSection;
