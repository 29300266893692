import React, { Fragment, useState, useEffect } from "react";
import ServicesBanner from "../../img/contact-us.jpg";
import { HashLink as Link } from 'react-router-hash-link';
import { faYoutube,
    faFacebook,
    faTwitter,
    faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faClock, // the clock icon
} from '@fortawesome/free-solid-svg-icons'
import FooterSection from "../footer/FooterSection";
import ReCAPTCHA from "react-google-recaptcha";
import ReactGa from 'react-ga'

import {toast } from 'react-toastify';

toast.configure()


let sectionBannerStyle = {
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundImage: "url(" + ServicesBanner + ")"
};

let sectionStyle = {
    color: '#7bc242',
    fontSize: '55px'
};

const ContactPage = () => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [note, setNote] = useState('');
    const [telNo, setTelNo] = useState('');
    const [serviceSelected, setServiceSelected] = useState('');
    const [captchaDemo, setCaptchaDemo] = useState('');
    const [isVerified, setIsVerified] = useState(false)
    const [resetAll, setResetAll] = useState(false)

    useEffect(() => {
        if (captchaDemo) {
            console.log("started, just a second...")
            captchaDemo.reset();
        }
    }, [resetAll]);

    const onChange = (value) => {

        if(!value.length){
            setIsVerified(false)
        }else {
            setIsVerified(true)
        }
    }


    const data = {
        name: name,
        phone: telNo,
        email: email,
        serviceSelected: serviceSelected,
        desc: note
    };

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    const clearFields = () => {
        setName('');
        setTelNo('')
        setEmail('');
        setNote('');
        setServiceSelected('');
    }

    const handleClick = () => {
        console.log("Handle clicked");
        ReactGa.event({
            category: 'Button',
            action : 'Send now button clicked on contact page'
        })
        if(email){
            if(isVerified){
                fetch("https://ggc62lohfa.execute-api.ap-southeast-2.amazonaws.com/prod/success-cps-submit-contact", requestOptions)
                    .then((res) => res.json())
                    .then((data) => {
                        clearFields();
                        toast.success('🦄 Thank you for filling out your information! We will contact you soon.', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        console.log(data);
                    })
                    .catch((err) => console.log(err))

                clearFields();
                setResetAll(true);
                setIsVerified(false)
            }else{
                toast.info(' 🦄You must not be a robot!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }

        } else {
            toast.info(' 🦄Email address is required! ', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setResetAll(true)
        }
    };




    return(
        <Fragment>
            <div className="pagehding-sec" style={sectionBannerStyle}>
                <div className="images-overlay"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-heading">
                                <h1>Contact Us</h1>
                            </div>
                            <div className="breadcrumb-list">
                                <ul>
                                    <li><Link to="home">Home</Link></li>
                                    <li><Link to="#">Contact us</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="contact-page-sec">
                <div className="container">
                    <div className="row pt-100 pb-100">
                        <div className="col-md-5">
                            <div className="contact-info">
                                <div className="contact-info-item">
                                    <div className="contact-info-icon">
                                        <img src={require("../../img/icon/phone.png")} alt=""/>
                                    </div>
                                    <div className="contact-info-text">
                                        <h2>phone</h2>
                                        <span>0415 129 978</span>
                                    </div>
                                </div>
                            </div>
                            <div className="contact-info">
                                <div className="contact-info-item">
                                    <div className="contact-info-icon">
                                        <img src={require("../../img/icon/envelope.png")} alt=""/>
                                    </div>
                                    <div className="contact-info-text">
                                        <h2>e-mail</h2>
                                        <span>
                                            <Link to="mailto:info@successcps.com.au">info@successcps.com.au</Link>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="contact-info">
                                <div className="contact-info-item">
                                    <div className="contact-info-icon">
                                        <img src={require("../../img/icon/map-marker.png")} alt=""/>
                                    </div>
                                    <div className="contact-info-text">
                                        <h2>address</h2>
                                        <span>Melbourne</span>
                                    </div>
                                </div>
                            </div>
                            <div className="contact-info">
                                <div className="contact-info-item">
                                    <p>
                                        <div className="contact-info-icon">
                                            <FontAwesomeIcon style={sectionStyle} icon={faClock}></FontAwesomeIcon>
                                        </div>
                                        Open Monday - Friday 6am - 9pm<br/>
                                        Weekends and Public Holidays 7am - 8pm
                                    </p>

                                </div>
                            </div>
                            <div className="social-profile">
                                <ul>
                                    <li>
                                        <a href={"https://www.facebook.com/Success-Carpet-Solutions-226242744069422"} target="_blank">
                                            <FontAwesomeIcon icon={faFacebook}></FontAwesomeIcon>
                                        </a>
                                    </li>
                                    <li>
                                        <a href={"https://twitter.com/ServicesSuccess"} target="_blank">
                                            <FontAwesomeIcon icon={faTwitter}></FontAwesomeIcon>
                                        </a>
                                    </li>
                                    <li>
                                        <a href={"#"} target="_blank">
                                            <FontAwesomeIcon icon={faLinkedin}></FontAwesomeIcon>
                                        </a>
                                    </li>
                                    <li>
                                        <a href={"https://youtube.com/channel/UC4WOQLlYCt5Z-j6_HYP6ntA"} target="_blank">
                                            <FontAwesomeIcon icon={faYoutube}></FontAwesomeIcon>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="contact-field">
                                    <h2>Write Your Message</h2>
                                    <div className="col-md-6 col-sm-6 col-xs-12">
                                        <div className="single-input-field">
                                            <input
                                                placeholder="Full Name"
                                                type="text"
                                                name="name"
                                                value={name}
                                                onChange={({target: {value}}) => setName(value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-xs-12">
                                        <div className="single-input-field">
                                            <input
                                                placeholder="Your E-mail"
                                                type="email"
                                                name="email"
                                                value={email}
                                                onChange={({target: {value}}) => setEmail(value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-xs-12">
                                        <div className="single-input-field">
                                            <input
                                                placeholder="Phone"
                                                type="text"
                                                name="phone"
                                                value={telNo}
                                                onChange={({target: {value}}) => setTelNo(value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-xs-12">
                                        <div className="single-input-field">
                                            <select
                                                name="services"
                                                value={serviceSelected}
                                                onChange={({target: {value}}) => setServiceSelected(value)}>
                                                <option value="Carpet Steam Cleaning">Carpet Steam Cleaning</option>
                                                <option value="Tile & Grout Clean">Tile & Grout Clean</option>
                                                <option value="Upholstery Steam Clean">Upholstery Steam Clean</option>
                                                <option value="Mattress cleaning">Mattress cleaning</option>
                                                <option value="Window cleaning Service">Window cleaning Service</option>
                                                <option value="Rug Cleaning">Rug Cleaning</option>
                                                <option value="Flood Damage Cleaning">Flood Damage Cleaning</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-12 message-input">
                                        <div className="single-input-field">
                                            <textarea
                                                name="message"
                                                placeholder="Message"
                                                value={note}
                                                onChange={({target: {value}}) => setNote(value)}
                                            ></textarea>
                                        </div>
                                    </div>
                                <div className="col-md-12 message-input">
                                <ReCAPTCHA
                                    ref={(el) => setCaptchaDemo(el)}
                                    size="normal"
                                    data-theme="dark"
                                    render="explicit"
                                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                    onChange={onChange}
                                />
                                </div>
                                    <div className="single-input-fieldsbtn">
                                    </div>
                                    <div className="single-input-fieldsbtn">
                                        <input
                                            value="send now"
                                            type="submit"
                                            name="send_message"
                                            onClick={handleClick}
                                        />
                                    </div>
                                <div className="single-input-fieldsbtn">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterSection/>
        </Fragment>
    );
}
export default ContactPage;
