import React, { Fragment } from "react";
import ServicesBanner from "../../img/about-us-banner.jpg";
import FooterSection from "../footer/FooterSection";
import { HashLink as Link } from 'react-router-hash-link';

let sectionStyle = {
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundImage: "url(" + ServicesBanner + ")"
};

const AboutPage = () => {
    return (
        <Fragment>
            <div className="pagehding-sec" style={sectionStyle}>
                <div className="images-overlay"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-heading">
                                <h1>About Us</h1>
                            </div>
                            <div className="breadcrumb-list">
                                <ul>
                                    <li><Link to="home">Home</Link></li>
                                    <li><Link to="#">about us</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <section className=" pt-100">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <img src={require("../../img/about-us-img.jpg")} width="100%" />
                        </div>
                        <div className="col-md-6">
                            <p>Success-cps Professional steam cleaning service has been in cleaning business for many
                                years and all of our carpet cleaners are highly specialised in cleaning industry with
                                more than 5 years experience. We specialise in office and domestic carpet, rug,
                                mattress, upholstery and window cleaning. We pride ourselves on our high criterion of
                                service so all of our professionals have been skilled to a high standard and clutch
                                industry recognized qualifications.</p>
                            <p>Success-cps Professional steam cleaning service has been providing comprehensive, quality
                                and efficient cleaning services like carpet steam cleaning, tile and grout cleaning,
                                upholstery cleaning, flood damage restoration and more across Melbourne area.</p>
                            <p>Success-cps Professional steam cleaning service is one of those carpet cleaning companies
                                in Melbourne who truly care about the health and protection of our clients, so we offer
                                eco-friendly cleaning service by well equipment and experience team.</p>
                            <p>Our customers in Melbourne can be confident that by choosing Success-cps Professional
                                steam cleaning service. If you have any requirement related to cleaning service then
                                call us on 0449 796 787 or send an email with your needs. We will best try to help you
                                ASAP.</p>
                        </div>
                    </div>
                </div>
            </section>
            <FooterSection/>
        </Fragment>
    );
}

export default AboutPage;
