import React, { Fragment } from "react";
import { HashLink as Link } from 'react-router-hash-link';

const WelcomeNote = () => {
    return(
        <Fragment>
            <div className="about-sec pt-100">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7">
                            <h2>Welcome to Success-cps Professional steam carpet
                                cleaning</h2>
                            <p>Success-cps Professional steam cleaning service has been
                                in cleaning business for many years and all of our carpet
                                cleaners are highly specialised in cleaning industry with
                                more than 5 years experience. We specialise in office and
                                domestic carpet, rug, mattress, upholstery and window
                                cleaning. We pride ourselves on our high criterion of
                                service so all of our professionals have been skilled to
                                a high standard and clutch industry recognized
                                qualifications.</p>
                            <p>Success-cps Professional steam cleaning service has been
                                providing comprehensive, quality and efficient cleaning
                                services like carpet steam cleaning, tile and grout
                                cleaning, upholstery cleaning, flood damage restoration
                                and more across Melbourne area.</p><Link className="book_online"
                                                                         to="contact">Read More</Link>
                        </div>
                        <div className="col-md-5"><img className="rounded_img" src={require("../../img/about-us.jpg")}  width="100%"/></div>
                    </div>
                </div>
            </div>
        </Fragment>
    );

}

export default WelcomeNote;
