import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter as Router, Link, Route, Switch} from "react-router-dom";

import Home from "./pages/home/HomePage";
import About from "./pages/about/AboutPage";
import Contact from "./pages/contact/ContactPage";
import HeaderHome from "./components/HeaderHome";
import ServicesPage from "./pages/services/ServicesPage";
import GalleryPage from "./pages/gallery/GalleryPage";
import HttpsRedirect from 'react-https-redirect';

import Helmet from 'react-helmet'
import ReactGa from "react-ga";


const App = () => {

    useEffect(() => {
        ReactGa.initialize('UA-188313961-1');
        //to report page view
        ReactGa.pageview(window.location.pathname + window.location.search);
    }, []);

    return (

        <div className="page-wrapper mean-container">
            <Helmet>
                <title>Success Corporate Property Services</title>
                <meta charSet="utf-8"/>
                <Link rel="icon" to="%PUBLIC_URL%/favicon.ico"/>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>
                <meta name="theme-color" content="#000000"/>
                <meta name="description" content="Success-cps Professional steam cleaning service has been in cleaning business for many years and all of our carpet cleaners are highly specialised in cleaning industry with more than 5 years experience. We specialise in office and domestic carpet, rug, mattress, upholstery and window cleaning. We pride ourselves on our high criterion of service so all of our professionals have been skilled to a high standard and clutch industry recognized qualifications.

Success-cps Professional steam cleaning service has been providing comprehensive, quality and efficient cleaning services like carpet steam cleaning, tile and grout cleaning, upholstery cleaning, flood damage restoration and more across Melbourne area.

Success-cps Professional steam cleaning service is one of those carpet cleaning companies in Melbourne who truly care about the health and protection of our clients, so we offer eco-friendly cleaning service by well equipment and experience team.

Our customers in Melbourne can be confident that by choosing Success-cps Professional steam cleaning service. If you have any requirement related to cleaning service then call us on 0449 796 787 or send an email with your needs. We will best try to help you ASAP.

"/>
                <Link rel="apple-touch-icon" to="%PUBLIC_URL%/logo192.png"/>
                <Link rel="manifest" to="%PUBLIC_URL%/manifest.json"/>
            </Helmet>
            <HeaderHome/>
            <div>
                <Switch>
                    <Route exact={true} path={['/', '/home']} component={Home}/>
                    <Route exaxt path="/services" component={ServicesPage}/>
                    <Route exaxt path="/about" component={About}/>
                    <Route exaxt path="/gallery" component={GalleryPage}/>
                    <Route exact path="/contact" component={Contact}/>
                </Switch>
            </div>

        </div>
    )
}

ReactDOM.render(
    <HttpsRedirect>
        <Router>
            <App/>
        </Router>
    </HttpsRedirect>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
