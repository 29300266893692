import React, { Fragment } from "react";

const WhyChooseUsSection = () => {
    return(
        <Fragment>
            <div className="why-choose">
                <div className="container">
                    <div className="row">
                        <div className="why-choose-inner">
                            <div className="sec-title">
                                <h1>Why Choose Us</h1>
                            </div>
                            <div className="why-choose-single">
                                <div className="media">
                                    <div className="inner-number">
                                        <h1>Done by qualified technicians</h1>
                                    </div>
                                </div>
                            </div>
                            <div className="why-choose-single">
                                <div className="media">
                                    <div className="inner-number">
                                        <h1>World class Customer Service</h1>
                                    </div>
                                </div>
                            </div>
                            <div className="why-choose-single">
                                <div className="media">
                                    <div className="inner-number">
                                        <h1>Removing the most common home stains</h1>
                                    </div>
                                </div>
                            </div>
                            <div className="why-choose-single">
                                <div className="media">
                                    <div className="inner-number">
                                        <h1>Suitable for synthetic and natural carpets</h1>
                                    </div>
                                </div>
                            </div>
                            <div className="why-choose-single">
                                <div className="media">
                                    <div className="inner-number">
                                        <h1>Using the latest professional methods</h1>
                                    </div>
                                </div>
                            </div>
                            <div className="why-choose-single">
                                <div className="media">
                                    <div className="inner-number">
                                        <h1>Removing most bacteria & allergens</h1>
                                    </div>
                                </div>
                            </div>
                            <div className="why-choose-single">
                                <div className="media">
                                    <div className="inner-number">
                                        <h1>Special products for stain protection</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );

}

export default WhyChooseUsSection;
