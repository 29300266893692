import React, { Fragment } from "react";
import Slideshow from "./Slideshow";
import WelcomeNote from "./WelcomeNote";
import ServiceSection from "./ServiceSection";
import WhyChooseUsSection from "./WhyChooseUsSection";
import CertificationSection from "./CertificationSection";
import FooterSection from "../footer/FooterSection";

const HomePage = () => (
    <Fragment>
        <Slideshow/>
        <WelcomeNote/>
        <ServiceSection/>
        <WhyChooseUsSection/>
        <CertificationSection/>
        <FooterSection/>
    </Fragment>
);

export default HomePage;
