import React, { Fragment } from "react";
import { HashLink as Link } from 'react-router-hash-link';

const ServiceSection = () => {
    return(
        <Fragment>
            <div className="service pt-100 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="sec-title">
                                <h1>Services</h1>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="service-item">
                            <div className="col-md-4 col-sm-6">
                                <div className="inner">
                                    <div className="media">
                                        <div className="service-thumb"><img src={require("../../img/services/1.jpg")} alt=""/></div>
                                        <div className="service-content">
                                            <h2><Link to="services#carpet-steam-cleaning">Carpet Steam Clean</Link></h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <div className="inner">
                                    <div className="media">
                                        <div className="service-thumb"><img src={require("../../img/services/2.jpg")} alt=""/></div>
                                        <div className="service-content">
                                            <h2><Link to="services#tile-grout-clean">Tile & Grout Clean</Link></h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <div className="inner">
                                    <div className="media">
                                        <div className="service-thumb"><img src={require("../../img/services/3.jpg")} alt=""/></div>
                                        <div className="service-content">
                                            <h2><Link to="services#upholstery-steam-clean">Upholstery Steam Clean</Link></h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <div className="inner">
                                    <div className="media">
                                        <div className="service-thumb"><img src={require("../../img/services/4.jpg")} alt=""/></div>
                                        <div className="service-content">
                                            <h2><Link to="services#mattress-cleaning">Mattress cleaning</Link></h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <div className="inner">
                                    <div className="media">
                                        <div className="service-thumb"><img src={require("../../img/services/5.jpg")} alt=""/></div>
                                        <div className="service-content">
                                            <h2><Link to="services#flood-damage-cleaning">Flood Damage Cleaning</Link></h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <div className="inner">
                                    <div className="media">
                                        <div className="service-thumb"><img src={require("../../img/services/6.jpg")} alt=""/></div>
                                        <div className="service-content">
                                            <h2><Link to="services#rug-cleaning">Rug cleaning</Link></h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <Link to="contact" className="btn_sp">Book Online</Link>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );

}

export default ServiceSection;
