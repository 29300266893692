import React, { Fragment } from "react";
import { HashLink as Link } from 'react-router-hash-link';

import { faYoutube,
    faFacebook,
    faTwitter,
    faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const FooterSection = () => {
    return(
        <Fragment>
            <footer className="footer1">
                <div className="footer-overlay"></div>




                <div className="footer-sec">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3 col-sm-6">
                                <div className="footer-wedget-one">
                                    <Link to="home"><img width="100" src={require("../../img/logo.png")} alt=""/></Link>
                                    <p>Success-cps Professional Cleaning professionals own
                                        their own expert equipment, which they take great
                                        pride in using to get you the best possible
                                        result.</p>
                                </div>
                            </div>
                            <div className="col-md-9 col-sm-6">
                                <div className="col-md-3 no-padding-left">
                                    <div className="footer-recent-news">
                                        <h2>Our Services</h2>
                                        <div className="footer-recent-inner">
                                            <h2>
                                                <Link to="services#carpet-steam-cleaning">Carpet Steam Cleaning</Link>
                                            </h2>
                                        </div>
                                        <div className="footer-recent-inner">
                                            <h2>
                                                <Link to="services">Commercial Carpet Cleaning</Link>
                                            </h2>
                                        </div>
                                        <div className="footer-recent-inner">
                                            <h2><Link to="services#rug-cleaning">Rug Cleaning</Link>
                                            </h2>
                                        </div>
                                        <div className="footer-recent-inner">
                                            <h2>
                                                <Link to="services#upholstery-steam-clean">Upholstery Cleaning</Link>
                                            </h2>
                                        </div>
                                        <div className="footer-recent-inner">
                                            <h2>
                                                <Link to="services">Professional Stain Removal</Link></h2>
                                        </div>
                                        <div className="footer-recent-inner">
                                            <h2>
                                                <Link to="services#mattress-cleaning">Mattress Cleaning</Link>
                                            </h2>
                                        </div>
                                        <div className="footer-recent-inner">
                                            <h2>
                                                <Link to="services#flood-damage-cleaning">Flood Damage Cleaning</Link>
                                            </h2>
                                        </div>
                                        <div className="footer-recent-inner">
                                            <h2>
                                                <Link to="services#window-cleaning-service">Window Cleaning</Link>
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 no-padding-right">
                                    <div className="bussiness-time">
                                        <h2>Find Us On Social Media</h2>
                                        <div className="social-profile">
                                            <ul>
                                                <li>
                                                    <a href={"https://www.facebook.com/Success-Carpet-Solutions-226242744069422"}
                                                       target="_blank">
                                                        <FontAwesomeIcon icon={faFacebook}></FontAwesomeIcon>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href={"https://twitter.com/ServicesSuccess"} target="_blank">
                                                        <FontAwesomeIcon icon={faTwitter}></FontAwesomeIcon>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href={"#"} target="_blank">
                                                        <FontAwesomeIcon icon={faLinkedin}></FontAwesomeIcon>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href={"https://youtube.com/channel/UC4WOQLlYCt5Z-j6_HYP6ntA"}
                                                       target="_blank">
                                                        <FontAwesomeIcon icon={faYoutube}></FontAwesomeIcon>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-5 no-padding-right">
                                    <div className="bussiness-time">
                                        <h2>Contact Infomation</h2>
                                        <div className="media">
                                            <div className="contact-inner-item">
                                                <div className="media-left"></div>
                                                <div className="media-body">
                                                    <span className="inner-text">0415 129 978</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="media">
                                            <div className="contact-inner-item">
                                                <div className="media-left"></div>
                                                <div className="media-body">
                                                    <span className="inner-text">
                                                        <Link to="mailto:info@successcps.com.au">info@successcps.com.au</Link>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="media">
                                            <div className="contact-inner-item">
                                                <div className="media-left"></div>
                                                <div className="media-body">
                                                    <span className="inner-text">successcps.com.au</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="media">
                                            <div className="contact-inner-item">
                                                <div className="media-left"></div>
                                                <div className="media-body">
                                                    <span className="inner-text">Open Monday - Friday 6am - 9pm<br/>
                                                            Weekends and Public Holidays 7am - 8pm
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>








                <div className="footer-bottom-sec">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="copy-right">
                                    <p>Copyright 2020 © Success Carpet Solutions</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="copy-right"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </Fragment>
    );

}

export default FooterSection;
